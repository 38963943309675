.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  padding-left: 10px;
  width: 3px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
}

body a {
  text-decoration: none;
}

body {
  height: 100vh;
  font-family: "Arial";
  color: white;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.myBox {
  background-color: white;
  width: 500px;
  height: 500px;
  clip-path: url(#svgPath);
  color: red;
}

.container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

.content {
  display: flex;
  gap: 5em;
  width: 100%;
  padding-top: 3em;
  position: relative;
}

.content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid white;
  transform: scaleX(1);
}

h1 {
  font-size: 4rem;
  width: 50vw;
  line-height: 97%;
  text-align: right;
}

section {
  height: 100vh;
  color: white;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
#clip {
  background: var(
    --linear-1,
    linear-gradient(135deg, #001277 0%, #148e7e 76.87%, #19a97f 100%)
  );

  background-attachment: fixed;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  font-size: 28vw;
  font-weight: bold;
  text-align: center;
}
.ql-toolbar {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.wave {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100vw;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slider-box .slick-track {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
